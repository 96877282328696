import { Stack, Typography } from "@mui/joy";
import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";

export default function ChatBox({ childRef }) {
  const messagesEndRef = useRef(null);

  const chat = useSelector((state) => state.chat);

  const {
    // chat
    messages,
    remoteTyping,
  } = chat;

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  return (
    <Stack
      sx={{
        overflowY: "scroll",
      }}
      ref={childRef}
      p={2}
      spacing={2}
    >
      {messages?.map((message, index) => (
        <Stack direction="row" spacing={1} key={index}>
          <Typography level="body-sm" color="neutral" fontWeight={500}>
            <Typography
              level="body-sm"
              color={message.self ? "success" : "danger"}
              fontWeight={700}
              pr={1}
            >
              {message.alert
                ? message.text
                : message.self
                ? "You:"
                : "Stranger:"}
            </Typography>
            {!message.alert ? message.text : ""}
          </Typography>
        </Stack>
      ))}
      {remoteTyping && (
        <Typography level="body-sm" color="danger" fontWeight={700}>
          typing...
        </Typography>
      )}
      <br />
      <div ref={messagesEndRef} />
    </Stack>
  );
}
