import { useEffect, useRef, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import {
  setVideoDevice,
  setLocalTyping,
  setRoom,
  setRemoteTyping,
  setLocalSpeaking,
  setRemoteSpeaking,
  addMessage,
  setStage,
  disconnectRoom,
  resetAll,
} from "./redux/chat";

import { Room, RoomEvent, VideoPresets, Track } from "livekit-client";

import axios from "axios";

import { API_BASE_URL, LIVEKIT_URL } from "./config";
import { createUid } from "./helpers";
import Layout from "./components/layout";
import { FullScreen, useFullScreenHandle } from "react-full-screen";

export default function App() {
  const [uid] = useState(localStorage.getItem("uid") || createUid());

  const chat = useSelector((state) => state.chat);

  const {
    // room and connection
    room,
    createNewRoom,

    // chat
    localTyping,
    interests,

    // device related
    videoDevice,
  } = chat;

  const dispatch = useDispatch();

  const remoteVideo = useRef(null);
  const remoteAudio = useRef(null);

  const parentRef = useRef(null);
  const childRef = useRef(null);

  const FSHandler = useFullScreenHandle();

  // max height detector
  useEffect(() => {
    const parentHeight = parentRef.current.clientHeight;
    childRef.current.style.maxHeight = `${parentHeight - 30}px`;
  }, []);

  // get a video device
  useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then((mediaDevices) => {
      const filteredMediaDevices = mediaDevices.filter(
        ({ kind }) => kind === "videoinput"
      );
      dispatch(setVideoDevice(filteredMediaDevices[0]));
    });
  }, [dispatch]);

  // typing observer timer
  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(setLocalTyping(false));
      dispatch(setRemoteTyping(false));
    }, 5000);

    return () => clearInterval(interval);
  }, [dispatch]);

  // send typing hook
  useEffect(() => {
    if (room && localTyping) {
      const textEncoder = new TextEncoder();
      let encodedMessage = textEncoder.encode("");
      room.localParticipant.publishData(encodedMessage, {
        topic: "TY",
      });
    }
  }, [localTyping, room]);

  const connect = async () => {
    if (window.innerWidth < 900 && !FSHandler.active) {
      try {
        FSHandler.enter();
      } catch {
        console.error("Unable to enter full screen!");
      }
    }
    dispatch(resetAll());
    dispatch(setStage("init"));

    const res = await axios(`${API_BASE_URL}/token`, {
      method: "POST",
      data: {
        px: uid,
        interests,
        create: createNewRoom,
      },
    });

    const token = res.data.token;

    const room = new Room({
      adaptiveStream: true,
      dynacast: true,
      videoCaptureDefaults: {
        resolution: VideoPresets.h216.resolution,
        deviceId: videoDevice.deviceId,
      },
    });

    // pre-warm connection, this can be called as early as your page is loaded
    room.prepareConnection(LIVEKIT_URL, token);

    // set up event listeners
    room
      .on(RoomEvent.TrackSubscribed, handleTrackSubscribed)
      .on(RoomEvent.TrackUnsubscribed, handleTrackUnsubscribed)
      .on(RoomEvent.ActiveSpeakersChanged, handleActiveSpeakerChange)
      .on(RoomEvent.Disconnected, handleDisconnected)
      .on(RoomEvent.Connected, handleConnected)
      .on(RoomEvent.LocalTrackUnpublished, handleLocalTrackUnpublished)
      .on(RoomEvent.ParticipantConnected, handleParticipantConnected)
      .on(RoomEvent.ParticipantDisconnected, handleParticipantDisconnected)
      .on(RoomEvent.DataReceived, handleDataReceived);

    await room.connect(LIVEKIT_URL, token);
    if (room.remoteParticipants.size) {
      dispatch(setStage("chatting"));
      dispatch(
        addMessage({
          text: "Connected",
          self: true,
          alert: true,
        })
      );
    } else {
      dispatch(setStage("connected"));
    }

    try {
      await room.localParticipant.enableCameraAndMicrophone();
    } catch {
      alert("Please allow camera and microphone access");
      window.location.reload();
    }

    dispatch(setRoom(room));
  };

  const handleConnected = () => {
    // WARNING: don't put setStage as "connected", we don't have access to room here.
  };

  const handleDisconnected = () => {
    dispatch(setStage("closed"));
    const existingVideoElement = remoteVideo.current.querySelector("video");
    if (existingVideoElement) {
      remoteVideo.current.removeChild(existingVideoElement);
    }
  };

  const handleDataReceived = (payload, participant, kind, topic) => {
    switch (topic) {
      case "CH":
        const textDecoder = new TextDecoder();
        let message = textDecoder.decode(payload);
        dispatch(
          addMessage({
            text: message,
            self: false,
            alert: false,
          })
        );
        dispatch(setRemoteTyping(false));
        break;

      case "TY":
        dispatch(setRemoteTyping(true));
        break;
      default:
        console.error("Unknown data", payload, participant, kind, topic);
        break;
    }
  };

  const handleTrackSubscribed = (track, publication, participant) => {
    let isLocal = false;
    if (participant?.ParticipantInfo?.name === uid) {
      isLocal = true;
    }

    if (track.kind === Track.Kind.Video) {
      const element = track.attach();
      if (!isLocal) {
        const existingVideoElement = remoteVideo.current.querySelector("video");
        if (existingVideoElement) {
          remoteVideo.current.replaceChild(element, existingVideoElement);
        } else {
          remoteVideo.current.appendChild(element);
        }
      }
    }
    if (track.kind === Track.Kind.Audio) {
      const element = track.attach();
      if (!isLocal) {
        const existingAudioElement = remoteAudio.current.querySelector("audio");
        if (existingAudioElement) {
          remoteAudio.current.replaceChild(element, existingAudioElement);
        } else {
          remoteAudio.current.appendChild(element);
        }
      }
    }
  };

  const handleTrackUnsubscribed = (track, publication, participant) => {
    // remove tracks from all attached elements
    track.detach();
  };

  const handleLocalTrackUnpublished = (publication, participant) => {
    // when local tracks are ended, update UI to remove them from rendering
    publication.track.detach();
  };

  const handleActiveSpeakerChange = (speakers) => {
    if (speakers.length) {
      let tempLocal = false;
      let tempRemote = false;
      speakers.forEach((speaker) => {
        if (speaker?.participantInfo?.name === uid) {
          tempLocal = true;
        } else {
          tempRemote = true;
        }
      });
      dispatch(setLocalSpeaking(tempLocal));
      dispatch(setRemoteSpeaking(tempRemote));
    } else {
      dispatch(setLocalSpeaking(false));
      dispatch(setRemoteSpeaking(false));
    }
  };

  const handleParticipantConnected = () => {
    dispatch(setStage("chatting"));
    dispatch(
      addMessage({
        text: "Connected",
        self: true,
        alert: true,
      })
    );
  };

  const handleParticipantDisconnected = async () => {
    dispatch(setStage("left"));
    dispatch(disconnectRoom());
    dispatch(
      addMessage({
        text: "Stranger left the chat",
        self: false,
        alert: true,
      })
    );
  };

  return (
    <>
      <FullScreen handle={FSHandler}>
        <Layout
          remoteVideo={remoteVideo}
          remoteAudio={remoteAudio}
          parentRef={parentRef}
          childRef={childRef}
          connect={connect}
        />
      </FullScreen>
    </>
  );
}
