import { configureStore } from "@reduxjs/toolkit";
import chat from "./chat";

export default configureStore({
  reducer: {
    chat,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: ["chat/setRoom", "chat/setVideoDevice"],
        // Ignore these field paths in all actions
        // ignoredActionPaths: [],
        // Ignore these paths in the state
        ignoredPaths: ["chat.videoDevice", "chat.room"],
      },
    }),
});
