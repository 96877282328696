import { Container, Grid, Stack } from "@mui/joy";
import { useSelector } from "react-redux";

import VideoGrid from "../videoGrid";
import ChatBox from "../chatBox";
import NewChat from "../newChat";
import ChatActions from "../chatActions";

export default function Desktop({
  remoteVideo,
  remoteAudio,
  parentRef,
  childRef,
  connect,
}) {
  const chat = useSelector((state) => state.chat);

  const {
    // room and connection
    stage,
  } = chat;

  return (
    <Stack justifyContent="space-between" alignItems="center">
      <Container
        maxWidth="xl"
        sx={{
          padding: "0px !important",
        }}
      >
        <Grid container>
          <VideoGrid remoteVideo={remoteVideo} />
          <Grid
            xs={12}
            md={7}
            pl={{
              xs: 0,
              md: 1,
            }}
            sx={{
              height: {
                xs: "50vh",
                md: "100vh",
              },
            }}
          >
            <Stack
              sx={{
                height: "100%",
              }}
            >
              <Stack
                style={{
                  border: "1px solid #e2e2e2",
                  backgroundColor: "#fff",
                  height: "100%",
                  overflow: "hidden",
                  position: "relative",
                }}
                ref={parentRef}
              >
                {stage === "chatting" ||
                stage === "left" ||
                stage === "closed" ? (
                  <ChatBox childRef={childRef} />
                ) : (
                  <NewChat childRef={childRef} connect={connect} />
                )}
              </Stack>
              <Stack
                pt={{
                  xs: 0.75,
                  md: 1,
                }}
              >
                <ChatActions connect={connect} />
              </Stack>
            </Stack>
          </Grid>
        </Grid>
        <Stack className="audio-wrapper" sx={{}} ref={remoteAudio}></Stack>
      </Container>
    </Stack>
  );
}
