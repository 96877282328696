import { CircularProgress, Stack, Typography } from "@mui/joy";
import { APP_NAME } from "../../config";
import { useSelector } from "react-redux";

export default function RemoteVideoLg({ remoteVideo }) {
  const chat = useSelector((state) => state.chat);

  const {
    // room and connection
    stage,

    // device related
    remoteSpeaking,
  } = chat;

  return (
    <Stack
      className="video-wrapper"
      justifyContent="center"
      alignItems="center"
      sx={{
        height: "50vh",
        bgcolor: "#555",
        transform: "scale(-1, 1)",
        border: `1px solid ${remoteSpeaking ? "lightgreen" : "#e2e2e2"}`,
        boxShadow: `0px 0px 5px ${
          remoteSpeaking ? "lightgreen" : "transparent"
        }`,
      }}
      ref={remoteVideo}
    >
      {Boolean(stage === "init" || stage === "connected") ? (
        <CircularProgress
          color="warning"
          determinate={false}
          size="lg"
          value={30}
          variant="plain"
        />
      ) : null}
      <Typography className="watermark" level="title-md" fontWeight={700}>
        {APP_NAME}
      </Typography>
    </Stack>
  );
}
