import { useSelector } from "react-redux";

import { Stack, Typography } from "@mui/joy";

import NewChatActions from "./newChatActions";

export default function NewChat({ childRef, connect }) {
  const chat = useSelector((state) => state.chat);

  const { stage } = chat;

  const LoadingMessage = () => (
    <>
      <Typography level="body-sm">Connecting...</Typography>
      <Typography level="body-xs" color="neutral">
        Please be respectful
      </Typography>
    </>
  );

  const getStageComponent = () => {
    switch (stage) {
      case "init":
        return <LoadingMessage />;
      case "connected":
        return <LoadingMessage />;
      default:
        return <NewChatActions connect={connect} />;
    }
  };

  return (
    <Stack
      sx={{
        overflowY: "scroll",
        width: "100%",
        height: "100%",
      }}
      ref={childRef}
      justifyContent="center"
      alignItems="center"
      pt={5}
      spacing={2}
    >
      {getStageComponent()}
    </Stack>
  );
}
