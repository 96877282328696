import { v4 as uuidv4 } from "uuid";

export const createUid = () => {
  const uid = uuidv4();
  localStorage.setItem("uid", uid);
  return uid;
};

const flipCreateValue = (value) => {
  switch (value) {
    case "0":
      return "1";
    default:
      return "0";
  }
};

export const getCreateValue = () => {
  // 1. get or create value
  let create = localStorage.getItem("create");
  if (!create) {
    create = `${Math.floor(Math.random() * 100 + 1) % 2 === 0}`;
  }

  // 2. flip the vlaue
  let createFlipped = flipCreateValue(create);

  // 3. update flipped value in storage
  localStorage.setItem("create", createFlipped);

  // 4. return flipped value
  return Boolean(parseInt(createFlipped));
};
