import { Button } from "@mui/joy";
import { useSelector } from "react-redux";
import Interests from "./interests";

export default function NewChatActions({ connect }) {
  const chat = useSelector((state) => state.chat);

  const { stage } = chat;

  return (
    <>
      <Button
        type="submit"
        disabled={stage === "init" || stage === "connected"}
        onClick={connect}
        size="sm"
      >
        {stage === "init" || stage === "connected" ? "Connecting" : "New Chat"}
      </Button>
      <Interests />
    </>
  );
}
