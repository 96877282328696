import { Box, Stack, Typography } from "@mui/joy";
import Webcam from "react-webcam";
import { APP_NAME } from "../../config";
import { useSelector } from "react-redux";

export default function LocalVideoSm() {
  const chat = useSelector((state) => state.chat);

  const {
    // device related
    videoDevice,
    localSpeaking,
  } = chat;

  return (
    <Box
      sx={{
        display: {
          xs: "block",
          md: "none",
        },
        position: "absolute",
        width: "100px",
        top: "1px",
        right: "1px",
        marginTop: "0px !important",
      }}
    >
      <Stack
        className="video-wrapper"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "100%",
          bgcolor: "#555",
          transform: "scale(-1, 1)",
          boxShadow: `0px 0px 5px ${
            localSpeaking ? "lightgreen" : "transparent"
          }`,
        }}
      >
        {videoDevice && (
          <Webcam
            videoConstraints={{
              deviceId: videoDevice.deviceId,
            }}
          />
        )}
        <Typography
          className="watermark"
          level="title-md"
          fontWeight={700}
          sx={{
            display: {
              xs: "none",
              md: "block",
            },
          }}
        >
          {APP_NAME}
        </Typography>
      </Stack>
    </Box>
  );
}
