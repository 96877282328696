import { useDispatch, useSelector } from "react-redux";
import { setInterest, setInterests, setMatchInterests } from "../redux/chat";

import {
  Checkbox,
  Chip,
  ChipDelete,
  FormControl,
  FormHelperText,
  Input,
  Stack,
} from "@mui/joy";

export default function Interests() {
  const chat = useSelector((state) => state.chat);

  const {
    // matching
    matchInterests,
    interest,
    interests,
  } = chat;

  const dispatch = useDispatch();

  const handleAddInterest = (e) => {
    e.preventDefault();
    dispatch(setInterests([...new Set([...interests, interest])]));
    dispatch(setInterest(""));
  };

  return (
    <Stack spacing={1} alignItems="flex-start">
      <Checkbox
        disabled
        label="Common interests (coming soon)"
        size="sm"
        checked={matchInterests}
        onChange={() => {
          if (matchInterests) {
            dispatch(setInterests([]));
          }
          dispatch(setMatchInterests(!matchInterests));
        }}
      />
      <Stack direction="row" spacing={0.5}>
        {interests.map((interest, index) => (
          <Chip
            color="neutral"
            endDecorator={
              <ChipDelete
                onClick={() =>
                  dispatch(
                    setInterests(interests.filter((i) => i !== interest))
                  )
                }
              />
            }
            size="sm"
            variant="outlined"
            key={index}
          >
            {interest}
          </Chip>
        ))}
      </Stack>
      {matchInterests && (
        <form onSubmit={handleAddInterest} style={{ width: "100%" }}>
          <FormControl>
            <Input
              fullWidth
              size="sm"
              disabled={interests.length >= 5}
              value={interest}
              onChange={(e) => dispatch(setInterest(e.target.value))}
              placeholder="Add upto 5 interests"
            />
            <FormHelperText>Type interest and hit Enter</FormHelperText>
          </FormControl>
        </form>
      )}
    </Stack>
  );
}
