import { Stack, Typography } from "@mui/joy";
import React from "react";
import Webcam from "react-webcam";
import { APP_NAME } from "../../config";
import { useSelector } from "react-redux";

export default function LocalVideoLg() {
  const chat = useSelector((state) => state.chat);

  const {
    // device related
    videoDevice,
    localSpeaking,
  } = chat;

  return (
    <Stack
      className="video-wrapper"
      justifyContent="center"
      alignItems="center"
      sx={{
        display: {
          xs: "none",
          md: "block",
        },
        height: "50vh",
        bgcolor: "#555",
        transform: "scale(-1, 1)",
        border: `1px solid ${localSpeaking ? "lightgreen" : "#e2e2e2"}`,
        boxShadow: `0px 0px 5px ${
          localSpeaking ? "lightgreen" : "transparent"
        }`,
      }}
    >
      {videoDevice?.deviceId && (
        <Webcam
          videoConstraints={{
            deviceId: videoDevice.deviceId,
          }}
        />
      )}
      <Typography
        className="watermark"
        level="title-md"
        fontWeight={700}
        sx={{
          display: {
            xs: "none",
            md: "block",
          },
        }}
      >
        {APP_NAME}
      </Typography>
    </Stack>
  );
}
