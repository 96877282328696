import { Grid, Stack } from "@mui/joy";
import LocalVideoSm from "./localVideo/localVideoSm";
import LocalVideoLg from "./localVideo/localVideoLg";
import RemoteVideoLg from "./remoteVideo/remoteVideoLg";

export default function VideoGrid({ remoteVideo }) {
  return (
    <Grid xs={12} md={5}>
      <Stack
        justifyContent="space-between"
        sx={{
          position: "relative",
        }}
      >
        <RemoteVideoLg remoteVideo={remoteVideo} />
        <LocalVideoLg />
        <LocalVideoSm />
      </Stack>
    </Grid>
  );
}
