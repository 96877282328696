import { Box } from "@mui/joy";

import Desktop from "./desktop";

export default function Layout(props) {
  return (
    <Box
      sx={{
        bgcolor: "#f2f2f2",
      }}
    >
      <Desktop {...props} />
    </Box>
  );
}
