import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getCreateValue } from "../../helpers";

export const disconnectRoom = createAsyncThunk(
  "chat/disconnectRoom",
  async (_, { getState }) => {
    const { chat } = getState();
    const { room } = chat;
    await room.disconnect();
  }
);

const initialState = {
  // room and connection
  room: null,
  stage: null, // init, connected, chatting, left, closed, reset
  createNewRoom: getCreateValue(),

  // matching
  matchInterests: false,
  interest: "",
  interests: [],

  // device related
  videoDevice: null,
  localSpeaking: false,
  remoteSpeaking: false,

  // chat
  messages: [],
  message: "",
  localTyping: false,
  remoteTyping: false,
};

export const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    setRoom: (state, { payload }) => {
      state.room = payload;
    },
    setStage: (state, { payload }) => {
      state.stage = payload;
    },
    resetMessages: (state) => {
      state.messages = [];
    },
    addMessage: (state, { payload }) => {
      state.messages = [...state.messages, payload];
    },
    setVideoDevice: (state, { payload }) => {
      state.videoDevice = payload;
    },
    setLocalSpeaking: (state, { payload }) => {
      state.localSpeaking = payload;
    },
    setRemoteSpeaking: (state, { payload }) => {
      state.remoteSpeaking = payload;
    },
    setMatchInterests: (state, { payload }) => {
      state.matchInterests = payload;
    },
    setInterest: (state, { payload }) => {
      state.interest = payload;
    },
    setInterests: (state, { payload }) => {
      state.interests = payload;
    },
    setMessage: (state, { payload }) => {
      state.message = payload;
    },
    setLocalTyping: (state, { payload }) => {
      state.localTyping = payload;
    },
    setRemoteTyping: (state, { payload }) => {
      state.remoteTyping = payload;
    },
    resetAll: (state) => {
      state.room = initialState.room;
      state.stage = initialState.stage;
      state.interest = initialState.interest;
      state.localSpeaking = initialState.localSpeaking;
      state.remoteSpeaking = initialState.remoteSpeaking;
      state.messages = initialState.messages;
      state.message = initialState.message;
      state.localTyping = initialState.localTyping;
      state.remoteTyping = initialState.remoteTyping;
      state.createNewRoom = getCreateValue();

      // don't reset the following -
      // state.matchInterests
      // state.interests
      // state.videoDevice
    },
  },
  extraReducers: (builder) => {
    builder.addCase(disconnectRoom.fulfilled, (state) => {
      state.room = initialState.room;
      state.localSpeaking = initialState.localSpeaking;
      state.remoteSpeaking = initialState.remoteSpeaking;
      state.localTyping = initialState.localTyping;
      state.remoteTyping = initialState.remoteTyping;
      state.message = initialState.message;
    });
  },
});

export const {
  setRoom,
  setStage,
  addMessage,
  resetMessages,
  setVideoDevice,
  setLocalSpeaking,
  setRemoteSpeaking,
  setMatchInterests,
  setInterest,
  setInterests,
  setMessage,
  setLocalTyping,
  setRemoteTyping,
  resetAll,
} = chatSlice.actions;

export default chatSlice.reducer;
