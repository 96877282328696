import { useDispatch, useSelector } from "react-redux";
import {
  addMessage,
  disconnectRoom,
  setLocalTyping,
  setMessage,
  setStage,
} from "../redux/chat";

import { Button, IconButton, Stack, Textarea } from "@mui/joy";

import { DataPacket_Kind } from "livekit-client";
import { Send } from "@mui/icons-material";

export default function ChatActions({ connect }) {
  const chat = useSelector((state) => state.chat);

  const {
    // room and connection
    room,
    stage,

    // chat
    message,
    localTyping,
  } = chat;

  const dispatch = useDispatch();

  const sendMessage = () => {
    if (message) {
      const textEncoder = new TextEncoder();
      let encodedMessage = textEncoder.encode(message);
      room.localParticipant.publishData(encodedMessage, {
        topic: "CH",
        reliable: DataPacket_Kind.RELIABLE,
      });
      dispatch(
        addMessage({
          text: message,
          self: true,
          alert: false,
        })
      );
      dispatch(setMessage(""));
    }
  };

  const getButtonText = () => {
    switch (stage) {
      case "init":
        return "Stop";
      case "connected":
        return "Stop";
      case "chatting":
        return "Stop";
      default:
        return "New";
    }
  };

  const handleButtonClick = () => {
    if (stage === "chatting") {
      dispatch(disconnectRoom());
      dispatch(
        addMessage({
          text: "You left the chat",
          self: false,
          alert: true,
        })
      );
    } else if (stage === "left" || stage === "closed") {
      dispatch(setStage("reset"));
    } else if (stage === "init" || stage === "connected") {
      window.location.reload();
    } else {
      connect();
    }
  };

  return (
    <Stack
      direction="row"
      spacing={{
        xs: 0.75,
        md: 1,
      }}
      justifyContent="space-between"
      alignItems="center"
    >
      <Button
        variant="solid"
        color="primary"
        size="sm"
        sx={{
          height: "100%",
          minWidth: {
            xs: "60px",
            md: "100px",
          },
        }}
        onClick={handleButtonClick}
      >
        {getButtonText()}
      </Button>
      <Textarea
        disabled={stage !== "chatting"}
        minRows={2}
        maxRows={2}
        size="sm"
        sx={{
          width: "100%",
        }}
        value={message}
        onChange={(e) => {
          dispatch(setMessage(e.target.value));
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            sendMessage();
          } else if (!localTyping) {
            dispatch(setLocalTyping(true));
          }
        }}
      ></Textarea>
      <Button
        disabled={stage !== "chatting"}
        variant="outlined"
        size="sm"
        color="neutral"
        sx={{
          display: {
            xs: "none",
            md: "block",
          },
          height: "100%",
          minWidth: {
            xs: "50px",
            md: "150px",
          },
          backgroundColor: "#fff",
        }}
        onClick={sendMessage}
      >
        Send <br /> (Enter)
      </Button>
      <IconButton
        disabled={stage !== "chatting"}
        variant="outlined"
        size="sm"
        color="neutral"
        sx={{
          display: {
            xs: "block",
            md: "none",
          },
          height: "100%",
          minWidth: {
            xs: "50px",
            md: "150px",
          },
          backgroundColor: "#fff",
        }}
        onClick={sendMessage}
      >
        <Send />
      </IconButton>
    </Stack>
  );
}
